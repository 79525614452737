import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19')
];

export const server_loads = [0,2];

export const dictionary = {
		"/(common)": [4,[2]],
		"/classes": [13],
		"/classes/new": [~16],
		"/classes/[classId]": [~14],
		"/classes/[classId]/devices": [~15],
		"/(common)/content/[...directory]": [~5,[2]],
		"/(common)/history": [~6,[2,3]],
		"/(common)/search": [~7,[2]],
		"/(common)/settings/account": [~8,[2]],
		"/(common)/settings/billing": [~9,[2]],
		"/settings/billing/process": [~17],
		"/(common)/settings/privacy": [~10,[2]],
		"/(common)/settings/security": [~11,[2]],
		"/simulator": [18],
		"/(common)/tasks/[tasksetId]": [~12,[2]],
		"/waitlist": [19]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';