import { dev } from "$app/environment";
import { handleErrorWithSentry, Replay } from "@sentry/sveltekit";
import * as Sentry from "@sentry/sveltekit";
import { analyticsService } from "$lib/services/analytics-service";
import { env } from "$env/dynamic/public";
import { createAuthService } from "@www/frontend";
import { injectSpeedInsights } from "@vercel/speed-insights";

console.log("client: " + __VERSION__);
if (env.PUBLIC_ENVIRONMENT === "production") injectSpeedInsights();

Sentry.init({
  enabled: !dev,
  dsn: "https://7ecc1adc3938f8386a1d0a820afc8bd7@o4506513304846336.ingest.us.sentry.io/4507219964067840",
  tunnel: "/sentry",
  environment: env.PUBLIC_SENTRY_ENVIRONMENT,
  release: __VERSION__ ?? "unknown",
  tracesSampleRate: 0.01,
  profilesSampleRate: 1.0,
  replaysSessionSampleRate: 0,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // If you don't want to use Session Replay, just remove the line below:
  integrations: [new Replay()],

  // Ignore list based off: https://gist.github.com/1878283
  ignoreErrors: [
    // Random plugins/extensions
    "top.GLOBALS",
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    "originalCreateNotification",
    "canvas.contentDocument",
    "MyApp_RemoveAllHighlights",
    "http://tt.epicplay.com",
    "Can't find variable: ZiteReader",
    "jigsaw is not defined",
    "ComboSearch is not defined",
    "http://loading.retry.widdit.com/",
    "atomicFindClose",
    // Facebook borked
    "fb_xd_fragment",
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    "bmi_SafeAddOnload",
    "EBCallBackMessageReceived",
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    "conduitPage",
    // Generic error code from errors outside the security sandbox
    // You can delete this if using raven.js > 1.0, which ignores these automatically.
    "Script error.",
  ],

  // Ignore list based off: https://gist.github.com/1878283
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /chrome-extension:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
});

const myErrorHandler = ({ error, event }: any) => {
  console.error("An error occurred on the client side:", error, event);
};

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry(myErrorHandler);

if (env.PUBLIC_AMPLITUDE_API_KEY) {
  analyticsService.initialize(env.PUBLIC_AMPLITUDE_API_KEY);
}
